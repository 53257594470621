@import '~ethos-design-system/src/components/Media/Media.scss';

.componentWrapper {
  @include for-phone-only {
    padding-bottom: 29px;
  }
  @include for-laptop-only {
    margin-top: var(--Space-12);
    margin-bottom: var(--Space-48);
  }
  z-index: 1;
  position: relative;
  padding-top: 20px;
  overflow: hidden;
}

.layoutContainer {
  @include for-phone-and-tablet {
    flex-direction: column;
  }
  display: flex;
}

.contentContainer {
  @include for-tablet-only {
    position: relative;
    right: 0;
  }
  @include for-phone-and-tablet {
    width: 100%;
  }
  @include for-desktop-only {
    padding-top: 30px;
  }
  width: 41%;
}

.eyebrow {
  @include for-phone-only {
    margin-bottom: var(--Space-32);
  }
  @include for-tablet-only {
    margin-bottom: var(--Space-40);
  }
  margin-bottom: var(--Space-32);
}

.heading {
  @include for-phone-only {
    margin-bottom: var(--Space-32);
    width: 81%;
  }
  @include for-tablet-only {
    width: 460px;
  }
  @include for-laptop-only {
    margin-bottom: var(--Space-32);
    width: 309px;
  }
  @media (min-width: 1200px) and (max-width: 1275px) {
    width: 500px;
  }
  margin-bottom: var(--Space-32);
  width: 519px;
}

.subheading {
  @include for-phone-only {
    width: 98.2%;
    margin-bottom: var(--Space-48);
  }
  @include for-tablet-only {
    margin-bottom: var(--Space-48);
    width: 460px;
  }
  @include for-laptop-only {
    width: 275px;
  }
  @media (min-width: 1200px) and (max-width: 1275px) {
    width: 490px;
  }
  margin-bottom: var(--Space-48);
  width: 504px;
}

.ctaWrapper {
  @include for-phone-only {
    margin-bottom: var(--Space-64);
    width: 99.3%;
  }
  z-index: 5;
  position: relative;
  width: 145px;
}

.imageContainer {
  @include for-tablet-only {
    position: relative;
    right: 0;
    height: 506px;
  }
  @include for-phone-and-tablet {
    width: 100%;
  }
  @include for-laptop-only {
    height: 780px;
  }
  display: flex;
  justify-content: center;
  width: 59%;
}

.imageWrapper {
  @include for-phone-only {
    max-height: 475px;
  }
  @media (min-width: 400px) and (max-width: 499px) {
    max-height: 545px;
  }
  @media (min-width: 500px) and (max-width: 599px) {
    max-height: 600px;
  }
  @include for-tablet-only {
    position: absolute;
    right: 0;
    bottom: var(--NegativeSpace-24);
  }
}

.background {
  @include for-phone-only {
    top: 648px;
  }
  @media (min-width: 450px) and (max-width: 599px) {
    top: 649px;
  }
  @include for-tablet-only {
    top: 441px;
  }
  @include for-phone-and-tablet {
    width: 904px;
  }
  @include for-laptop-only {
    top: 283px;
    width: 1200px;
  }
  position: absolute;
  top: 23px;
  right: 0;
  z-index: -1;
  width: 2560px;

  svg {
    @include for-phone-and-tablet {
      width: 904px;
      height: 390px;
    }
    @include for-laptop-only {
      width: 1200px;
      height: 518px;
    }
    width: 2560px;
    height: 1104px;
  }
}

.foreground {
  @include for-phone-only {
    top: 788px;
  }
  @media (min-width: 450px) and (max-width: 599px) {
    top: 805px;
  }
  @include for-tablet-only {
    top: 590px;
  }
  @include for-phone-and-tablet {
    width: 904px;
  }
  @include for-laptop-only {
    top: 480px;
    width: 1200px;
  }
  position: absolute;
  top: 425px;
  right: 0;
  z-index: 2;
  width: 2560px;

  svg {
    @include for-phone-and-tablet {
      width: 904px;
      height: 390px;
    }
    @include for-laptop-only {
      width: 1200px;
      height: 518px;
    }
    width: 2560px;
    height: 1104px;
    z-index: 3;
  }
}
